<template>
  <div :class="PanelClass" v-if="isActive">
    
    <div  v-show="priorValid" class="availdiv">
      <div class="horizFlexStretch">
        <div class="product-lookup">
          <input ref="lookupproduct" type="search"  :placeholder="productlookuptext" class="textbox" v-model="productString" @keyup.enter="lookupProduct()" @input="searchchange"/>
          <button class="btn-small actionbutton" @click="lookupProduct">Lookup</button>
        
        </div>
        <div v-if="showReturn">
          <confirm :showButton="trueParam" buttonclass="btn-red actionbutton " body="Do you want to start a return ticket?" title="Return Products" @yes="startreturn" v-if="!isReturn"/>
          <!-- <button class="btn-red actionbutton " @click="startreturn" v-if="!isReturn">Return Products</button> -->
          <label v-if="isReturn" class="returnlabel">Is Return</label>
        </div>
      </div>
      
     <div v-if="pleaseWait">
        <label class="pleasewait" for>Please Wait...</label>
      </div>

      <!-- products from scan -->
      <div class="lineitemavailable" v-if="scannedItems.length!=0">
          <availableitem
            v-for="cartItem in scannedItems"
            v-bind:key="cartItem.id"
            v-bind:cartItem="cartItem" @additem="addScannedItem"
          />
     </div>

      <div>
        <hr>
      </div>
      

      <div class="lineitemavailable" v-if="availableCartItemTypes.length>1" >
          <availableitem
            v-for="cartItem in availableCartItemTypes"
            v-bind:key="cartItem.id"
            v-bind:cartItem="cartItem" @additem="setType(cartItem)"
          />
     </div>

    

    <!-- products filtered by type -->
     <div class="lineitemavailable scrollbox" v-if="!useProductGroups">
       <availableitem
        v-for="cartItem in filterCartItems"
        v-bind:key="cartItem.ID"
        v-bind:cartItem="cartItem" @additem="addCartItem"
      />
     </div>
      
      <!-- products by group -->
    <div class="lineitemavailable scrollbox" v-if="useProductGroups">
       <availableitem
        v-for="cartItem in cartItemsByGroup"
        v-bind:key="cartItem.ID"
        v-bind:cartItem="cartItem" @additem="addGroupCartItem"
      />
     </div>

    </div>
    <div class="lineitemadded " >
      <div class="cartlines">
        <label for class="text subtitle">Cart</label>
        <CartItem v-for="cartItem in cartItems" v-bind:key="cartItem.id" v-bind:cartItem="cartItem"  @removeitem="removeitem" @refresh="refresh"  @updatequantity="updateitemquantity" @returnitem="returnitem"/>
        <label for class="text text-right totaltext">Subtotal: {{ ticket.subTotal|toCurrency }}</label>
       
          
          <label for class="text text-right totaltext discountcolor">Discount: {{ ticket.discountAmount|toCurrency }}</label>
          <div class="rightjustify disclink">
            <admindiscountwhole :ticketId="ticket.id" v-if="ShowAdminDiscount" @refresh="refresh"/>
          </div>
          
       
        
        <label for class="text text-right totaltext">Tax: {{ ticket.tax|toCurrency }}</label>
        <label for class="text text-right totaltext paycolor">Due: {{ ticket.total|toCurrency }}</label>
        <label class="eligiblemessage"  v-if="cartEligibleForFA">*All items in Cart Eligible For Financial Aid*</label>
      </div>
      
      <!-- <label for class="text text-right totaltext">Subtotal: {{ cartSubTotal|toCurrency }}</label>
      <label for class="text text-right totaltext">Tax: {{ cartTax|toCurrency }}</label>
      <label for class="text text-right totaltext paycolor">Due: {{ cartTotal|toCurrency }}</label> -->
      <div class="nextbutton">
        <button class="btn actionbutton" @click="toNext" v-show="isValid && ticket.total!=0">Continue to Payment</button>
        <button class="btn actionbutton" @click="process" v-show="isValid && ticket.total==0">Complete Transaction</button>
      </div>
    </div>

    <giftcard :showButton="trueParam" ref="giftcard" @save="saveGiftCard"/>
    <setprice ref="setprice" :minimumUnitPrice="productToGetPrice.minimumUnitPrice" @save="saveProductWithVariablePrice"/>
  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import CartItem from "../actions/CartItem.vue";
import availableitem from '../actions/availableitem.vue'
import format from '../../style/format.js'
import datamethods from '../../services/datamethods.js'
import giftcard from '../actions/giftcard.vue'
import setprice from '../actions/setprice.vue'
import confirm from '../actions/confirm.vue'
import admindiscountwhole from '../actions/admindiscount_whole.vue'
export default {
  data() {
    return {
      availableCartItems_filtered:[],
      cartItemsByGroup:[],
      scannedItems:[],
      productlookuptext:'Lookup Product',
      productString:'',

      giftCardToAdd:{},
      trueParam:true,
      selectedGuid:null,
      addingProduct:false,
      productToGetPrice:{},
     
    };
  },
  props: ["panelIndex"],
  mixins:[format,datamethods],
  components: {
    CartItem,
    availableitem,
    giftcard,
    admindiscountwhole,
    setprice,
    confirm
  },
  computed: {
    ...mapFields([
            'panels',
            'cartItems',
            'availableCartItems',
            'selectedCartItemType',
            'availableCartItemTypes',
            'cartTotal',
            'cartSubTotal',
            'cartItemCount',
            'cartTax',
            'payTotal',
            'due',
            'change',
            'isCash',
            'inPayment',
            'pleaseWait',
            'responseMessage',
            'ticket',
            'guest',
            'cartEligibleForFA',
            'guestLocationId',
            'isReturn'
        ]),
   
    useProductGroups(){
      if(window.USE_PRODUCT_GROUPS==undefined){
        return false
      }else{return window.USE_PRODUCT_GROUPS}
    },
  
    isActive: function() {
      return this.panels[this.panelIndex].active;
    },
    isValid: function() {
      return this.panels[this.panelIndex].valid;
    },
    priorValid: function() {
      return this.panels[this.panelIndex - 1].valid;
    },
    PanelClass: function() {
      var classes = "panel-full panel cartfull ";
      if (this.inPayment == true) {
        classes += " panel-unavailable";
      }
      return classes;
    },
    filterCartItems(){
      if(window.SELECT_PRODUCTS_BY_GUEST){
        return this.availableCartItems
      }

        var id=this.selectedCartItemType
        let temp = this.availableCartItems;
        if(temp!=undefined){
            temp = temp.filter((item)=>{
                return (item.typeId==id)
            })
        }
        
        return temp

             
    },
     AutoAddProduct(){
      if(window.AUTO_ADD_PRODUCT==undefined){return false}
      else{return window.AUTO_ADD_PRODUCT}
    },
    AutoAddProductStudentOnly(){
      if(window.AUTO_ADD_PRODUCT_STUDENT_ONLY==undefined){return false}
      else{return window.AUTO_ADD_PRODUCT_STUDENT_ONLY}
    },
    
    AutoStartOver(){
      
      if(window.AUTO_START_OVER==undefined){return false}
      else{return window.AUTO_START_OVER}
    },
    ShowAdminDiscount(){
      if(window.SHOW_ADMIN_DISCOUNT==undefined){return false}
      else{return window.SHOW_ADMIN_DISCOUNT}
    },
    showFinancialAid(){
      if(window.SHOW_FINANCIALAID==undefined){return false}
      else{return window.SHOW_FINANCIALAID}
    },
    
    AutoAddScannedProduct(){
      if(window.AUTO_ADD_SCANNED_PRODUCT==undefined){return false}
      else{return window.AUTO_ADD_SCANNED_PRODUCT}
    },
    showReturn(){
      if(window.SHOW_RETURN==undefined){return false}
      else{return window.SHOW_RETURN}
    }
  },
  watch:{
    isActive:function(val){
      if(this.isActive){this.show()}
    }
  },
  methods: {
    
    refresh(){
      console.log('full refresh')
      this.$emit('refreshticketrebuild')
    },
    show(){
    this.$nextTick(() => {
      
      this.$refs.lookupproduct.focus();
    })
   },
   searchchange(event){
    if(event.target.value.trim().length===0){
      this.scannedItems=[]
    }
   },
    startCart(){
      //console.log("startcart!")
      this.productString=''
      this.scannedItems=[]
      this.refreshCart(); //so it refreshes what we see
      
      //this.$store.dispatch('startOver_Cart');
      if(this.AutoAddProduct){
        if(this.AutoAddProductStudentOnly==false ||this.guest.isStudent==true ){
          this.GetDefaultProduct()
        }
        
        
      }
      this.getProductsByGroup()
    },

    async GetDefaultProduct(){
      let req = {
            locationId:this.ticket.locationId
          
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'defaultproduct_bytime')
        //handle response
     
        if(response.Successful){
          if(response.productList.length==0){return -1}
           var productId=response.productList[0].id
           //console.log('pid ' + productId)
           this.scannedItems=[]
            let req = {
                
                productId:productId
              }
              //call api
              this.$store.dispatch('beforeAPI');
              var response = await this.callAPI(req,'productlookup')
              //handle response
          
              if(response.Successful){
                if(response.productList.length>0){
                  this.addSelectedItem(response.productList[0],true)
                }
                
              }else{
                this.responseMessage = response.Message
              }
              this.pleaseWait=false;
        }else{
          //this.responseMessage = response.Message
          return -1
          
        }
        this.pleaseWait=false;
    },


    setType(cartitem){
      
      this.selectedCartItemType=cartitem.id
      this.selectedGuid = cartitem.guid
      var i;
      for (i = 0; i <= this.availableCartItemTypes.length - 1; i++) {
          if (this.availableCartItemTypes[i].id == cartitem.id) {
              this.availableCartItemTypes[i].selected=true
          }else{this.availableCartItemTypes[i].selected=false}
        }
        if(window.SELECT_PRODUCTS_BY_GUEST){
          this.getProductsByType()
        }else{
          this.getProductsByGroup()
        }
      
    },
    async getProductsByGroup(){
        let req = {
            locationId:this.ticket.locationId,
            productGroupId:this.selectedCartItemType
        }
          this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'products_bygroup')
        //handle response
        if(response.Successful){
              this.cartItemsByGroup=response.productList
        }else{
          this.responseMessage = response.Message
        }
        this.pleaseWait=false;
    },
    async getProductsByType(){
      let req = {
          productTypeGUID:this.selectedGuid,
          locationId:this.guestLocationId
        }      
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'products')
        //handle response
     
        if(response.Successful){
          
          let tmpItems= response.productList
           
          if (tmpItems == undefined) {
            let noneItem = dataModel.NoItemsItem();
            this.availableCartItems.push(noneItem);
          } else {
            this.availableCartItems = tmpItems;
          }

      
        }else{
          this.responseMessage = response.Message
        }
        this.pleaseWait=false;
    },
    addCartItem(cartItemID) {
      
        var i;
        for (i = 0; i <= this.availableCartItems.length - 1; i++) {
          if (this.availableCartItems[i].id == cartItemID) {
              this.addSelectedItem(this.availableCartItems[i])
          }
        }
    },
    addGroupCartItem(cartItemID){
     // console.log('addgrpcartitem')
      var i;
        for (i = 0; i <= this.cartItemsByGroup.length - 1; i++) {
          if (this.cartItemsByGroup[i].id == cartItemID) {
              this.addSelectedItem(this.cartItemsByGroup[i])
          }
        }
    },
    addScannedItem(cartItemID) {
  
        var i;
        for (i = 0; i <= this.scannedItems.length - 1; i++) {
          if (this.scannedItems[i].id == cartItemID) {
              this.addSelectedItem(this.scannedItems[i])
          }
        }
    },
    addSelectedItem(cartItem,isauto) {
    //  console.log('addsel')
        var cartItemID = cartItem.id
        var newItem;
        var i;
        var j;
 
        //see if item already in cart, if so, incr qty
        var found=false;
        for (j = 0; j <= this.cartItems.length - 1; j++) {
          if (this.cartItems[j].id == cartItemID) {
            found=true;
    
            if(this.isReturn){
              this.cartItems[j].quantity=Number(this.cartItems[j].quantity) - 1
            }else{
              this.cartItems[j].quantity=Number(this.cartItems[j].quantity) + 1
            }
            
            this.updateQuantity(this.cartItems[j])

            //force update of quantity on the ui
            this.panels[this.panelIndex].active=false;
            this.$nextTick(() => {
              this.panels[this.panelIndex].active=true;
            });
          }
        }
        //add anew one
        if(!found){
          newItem = cartItem;
          newItem.quantity=1
          newItem.isAuto=isauto
          this.addItemToData(newItem)
          
        }else{
          
        }
        
        
    },
    updateitemquantity(cartItem, qty){
      cartItem.quantity=qty
      this.updateQuantity(cartItem)
    },
    removeitem(cartItemID){
      var i;
        for (i = 0; i <= this.cartItems.length - 1; i++) {
          if (this.cartItems[i].id == cartItemID) {
            this.removeItemFromData(this.cartItems[i])
              this.cartItems.splice(i,1)
              
              break; //only take this one
          }
        }  
    },
    startreturn(){
      this.isReturn=true
      //if any items, call return on them. if its a free item, remove it
      var i;
      for (i = 0; i <= this.cartItems.length - 1; i++) {
        var islast = i==this.cartItems.length-1
        if(this.cartItems[i].price==0){
          this.removeforreturn(this.cartItems[i].lineItemId,islast)
        }else{
          this.returnitem(this.cartItems[i].lineItemId,islast)
        }
        
      }
    },
    async returnitem(id, islast=false){
      console.log(' return item')
      let req = {
          ticketLineItem:{
            id:id,
          },

        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'refundticketlineitem')
        //handle response
     
        if(response.Successful){
           if(islast){this.refresh()}
        }else{
          this.responseMessage = response.Message
        }
        this.pleaseWait=false;
    },
    async removeforreturn(id, islast=false){
      console.log('remove for return')
      let req = {
          ticketLineItem:{
            id:id
          }
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'removeticketlineitem')
        //handle response
     
        if(response.Successful){
          if(islast){this.refresh()}
        }else{
          this.responseMessage = response.Message
        }
        this.pleaseWait=false;
    },
    updateTotals(){
      this.$emit('refreshticket')
    },
    refreshCart(){
      
      var i;
      var total = 0;
      var count = 0;
      var tax = 0; //get from db
      var faEligible=this.cartItems.length>0 && this.showFinancialAid;

     // console.log('cartitemlength ' + this.cartItems.length)
     
        for (i = 0; i < this.cartItems.length ; i++) {
          total = total + (Number(this.cartItems[i].price) * Number(this.cartItems[i].quantity));
          count = count + Number(this.cartItems[i].quantity)
          if(this.showFinancialAid){
            if(this.cartItems[i].isFinancialAidEligible==false){faEligible=false}
          }
          
        }
        //console.log('updatetotals ' + count + ' ' + total)
        //set fa eligible flag
        this.cartEligibleForFA=faEligible 
        //this.cartSubTotal=total;
        //this.cartTax=tax;
        //this.cartTotal = total + tax;
        this.cartItemCount = count;
        this.due = this.ticket.total-this.payTotal;

        //account for change
        if(this.due<0 && this.isCash==true){
          this.change=this.due * -1;
          this.due=0
        }

        //var amt = this.$options.filters.toCurrency(this.cartTotal);
        var amt = this.$options.filters.toCurrency(this.ticket.total);
        this.panels[1].title = 'Cart: ' + amt + ' (' + this.cartItemCount + ')';

        if(this.cartItemCount!=0){
          this.panels[1].valid = true;
        //  console.log('due ' + this.ticket.total)
          if(this.ticket.total!=0){
            this.panels[2].available = true;
          }else{
            this.panels[3].available = true;
          }
          
          
        }else{
          this.panels[1].valid = false;
        this.panels[2].available = false;
        }

        
    },
    toNext: function() {
      this.lookupString=""
      var nextPanelIndex = Number(this.panelIndex) + 1;
      this.$store.dispatch('setActivePanel',nextPanelIndex);
    },
    toComplete: function() {
      this.lookupString=""

      if(this.AutoStartOver)      {
        this.startOver();
      }else{
        var nextPanelIndex = Number(this.panelIndex) + 2;
        this.$store.dispatch('setActivePanel',nextPanelIndex);
      }
      
    },
    startOver: function() {
      this.$store.dispatch('startOver');
    },
    async process(){
      this.inPayment=true
      let req = {
          ticket:this.ticket
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'processticketpayment')
        //handle response
     
        if(response.Successful){
           this.inPayment=false
           this.toComplete()
        }else{
          this.responseMessage = response.Message
        }
        this.pleaseWait=false;
    },
    getGiftInfo(item){
      //create new object, so we aren't editing the real item
      this.giftCardToAdd={
        code:item.code,
        price:item.price,
        giftCardNumber:null,
        name:item.name,
        quantity:item.quantity,
        isFinancialAidEligible:item.isFinancialAidEligible,
        isGiftCard:true
      }
      this.$refs.giftcard.startAdd()
    },
    getPrice(item){
      //variable price produt
      this.productToGetPrice={
        code:item.code,
        price:item.price,
        minimumUnitPrice:item.minimumUnitPrice,
        name:item.name,
        quantity:item.quantity,
        isFinancialAidEligible:item.isFinancialAidEligible,
      }
      this.$refs.setprice.start()
    },
    saveGiftCard(amount, number){
      this.giftCardToAdd.price=amount
      this.giftCardToAdd.giftCardNumber = number
      this.addItemToData(this.giftCardToAdd)
    },
    saveProductWithVariablePrice(price){
      this.productToGetPrice.price=price
      this.addItemToData(this.productToGetPrice)
    },
    async addItemToData(item){
      if(this.addingProduct){return}
      
     // console.log('additemtodata')
      //handle gift cards
      if((item.isPurchaseGiftCard || item.isAddValueGiftCard) && item.giftCardNumber==null){
       // console.log('need gift info')
        //go do gift things first
        this.getGiftInfo(item)
        return;
      }

      //handle variable price items
      if((item.isVariablePrice && item.price==0)){
       // console.log('var')
        this.getPrice(item)
        return;
      }

      
      this.addingProduct=true

      let req = {
            ticketId:this.ticket.id,
            productCode:item.code,
            productId:item.id,
            price:item.price,
            locationId:this.ticket.locationId,
            businessDayId:this.ticket.businessDayId,
            personId:this.ticket.guestId,
           giftCardNumber:item.giftCardNumber,
           isGiftCard:item.isGiftCard
        }
      //  console.log(req)
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'addticketlineitem')
        //handle response
     
        if(response.Successful){
           item.lineItemId=response.ResponseInteger
           this.cartItems.push(item);

           //clear gc
           this.addingProduct=false
           this.giftCardToAdd={}
           
          //if auto add, clear scanned items
          if(this.AutoAddScannedProduct && this.scannedItems.length==1){
            this.scannedItems=[]
          }

          //if this is a return, call that before refreshing
          if(this.isReturn){this.returnitem(item.lineItemId,true)}

           this.updateTotals()
        }else{
          this.responseMessage = response.Message
          this.addingProduct=false
        }
        this.pleaseWait=false;
    },
    async removeItemFromData(item){
      let req = {
          ticketLineItem:{
            id:item.lineItemId
          }
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'removeticketlineitem')
        //handle response
     
        if(response.Successful){
           this.updateTotals();
        }else{
          this.responseMessage = response.Message
        }
        this.pleaseWait=false;
    },
    async updateQuantity(item){
      //console.log('upqty')
      let req = {
          ticketLineItem:{
            id:item.lineItemId,
            productId:item.id,
            quantity:item.quantity,
            unitPrice:item.unitPrice,
            ticketId:this.ticket.id
          },
          personId:this.ticket.guestId,
          businessDayId:this.ticket.businessDayId,
          locationId:this.ticket.locationId,
          ticketLineItemDiscounts:this.ticket.lineItemDiscounts
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'updateticketlineitem_quantity')
        //handle response
     
        if(response.Successful){

          //if auto add, clear scanned items
          if(this.AutoAddScannedProduct && this.scannedItems.length==1){
            this.scannedItems=[]
          }

           this.updateTotals();
        }else{
          this.responseMessage = response.Message
        }
        this.pleaseWait=false;
    },
    async lookupProduct(){
      if(this.productString==null){return}
      if(this.productString==''){return}
      //console.log('lookup')
      this.scannedItems=[]
      let req = {
          locationId:this.ticket.locationId,
          lookupString:this.productString
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'productlookup')
        //handle response
     
        if(response.Successful){
           this.scannedItems=response.productList

          //auto add
          if(this.AutoAddScannedProduct){
            if(this.scannedItems.length==1){
              this.addScannedItem(this.scannedItems[0].id)
              this.productString=null
              
            }
          }

        }else{
          this.responseMessage = response.Message
        }
        this.pleaseWait=false;
    }
  },
  
  
};
</script>

<style scoped>
/* .availdiv{
  display:grid;
  grid-template-columns: auto;
  grid-template-rows:60px auto  auto 20px auto auto;
  height:75vh;
} */
.cartfull {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "itemavailable itemadded";
  padding: 10px;
}

.lineitemavailable {
  /* grid-area: itemavailable; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
}
.lineitemadded {
  grid-area: itemadded;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 60px;
  grid-template-areas:
    "itemadded"
    "next";
  padding: 10px;


  /* display: flex;
  flex-direction: column;
  border-left: 1px solid gray;
  padding-left:20px;
  margin-left:10px; */
}
.cartlines{
  display: flex;
  flex-direction: column;
  border-left: 1px solid gray;
  padding-left:20px;
  margin-left:10px;
}
.product-lookup {

  display: flex;
  flex-direction: row;
}
.scrollbox{
  max-height:42vh;
  /* max-height:100%; */
  overflow-y: auto;
  overflow-x:hidden;

}
.disclink{
  margin-right:10px;
  font-size:inherit;
}
.returnlabel{
  font-size:larger;
  font-weight:bold;
  margin:20px 0px 0px 0px;
  color:red;
}
</style>